<template>
  <v-autocomplete
    v-model="entity"
    :items="listEntitiesSelect"
    :label="$gettext(label)"
    v-on:keyup="searchAutoComplete"
    :search-input.sync="searchText"
    :loading="isLoadingAutoComplete"
    :disabled="disabled"
    :error-messages="errorMessages"
    return-object
    :outlined="isOutlined"
    :dense="isDense"
    :hide-details="hideDetails"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { difference } from 'lodash'

import { errorSnackbar } from '@/core/service/utils'

export default {
  name: 'AutoCompleteLancamentoFIa',
  // props: ['typesEntities', 'inativos'],
  props: {
    entityProps: {
      type: Object,
      default: () => {}
    },
    typesEntities: {
      type: Array,
      default: () => []
    },
    inativos: {
      type: Boolean,
      default: null
    },

    listaItensSelectProps: {
      type: Object,
      default: () => {}
    },

    label: {
      type: String,
      default: 'Terminal'
    },

    parentId: {
      type: Number,
      default: null
    },

    stop: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    errorMessages: {
      type: Array,
      default: () => []
    },

    treeProps: {
      type: Boolean,
      default: false
    },

    isDense: {
      type: Boolean,
      default: true
    },

    isOutlined: {
      type: Boolean,
      default: false
    },

    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoadingAutoComplete: false,
    modelAutoComplete: null,
    searchText: null,
    loading: false,
    entity: null,
    listEntitiesSelect: []
  }),

  mounted () {
    if (!this.stop) {
      this.getEntities(this.typesEntities, {'Entities:deleted_>=': this.inativos ? '1970-01-01' : undefined})
    }

    this.entity = this.entityProps
  },

  watch: {
    listaItensSelect (val) {
      this.$emit('update:listaItensSelectProps', val)
    },

    entity (val) {
      this.$emit('update:entityProps', val)
    },

    entityProps (val) {
      this.entity = val
    },

    inativos () {
      this.getEntities(this.typesEntities, {'Entities:deleted_>=': this.inativos ? '1970-01-01' : undefined})
    },

    parentId () {
      this.listEntitiesSelect = []
      this.getEntities(this.typesEntities, {'Entities:deleted_>=': this.inativos ? '1970-01-01' : undefined})
    },

    typesEntities (val, valold) {
      let differeArray = difference(val, valold)

      if (differeArray.length)
        this.getEntities(val, {'Entities:deleted_>=': this.inativos ? '1970-01-01' : undefined})
    }
  },

  computed: {
    ...mapGetters('entities', ['listaItensSelect'])
  },

  methods: {
    ...mapActions('entities', ['getListEntitiesTreeLancamentoFinanceiro']),
    searchAutoComplete(event) {
      switch (event.keyCode) {
        case 37: // Left 
        case 38: // Up 
        case 39: // Right 
        case 40: // Down 
        case 13: // Enter 
        case 17: // Ctrl 
        case 18: // Alt 
        case 92: // Meta 
          return
      }

      clearTimeout(this.debounce)
      const vm = this
      this.debounce = setTimeout(() => {
        if (vm.searchText === null || vm.searchText.length === 0) {
          vm.pesquisar('')
          return
        }

        vm.pesquisar(vm.searchText)
      }, 600)
    },

    pesquisar (val) {
      this.isLoadingAutoComplete = true
      this.getEntities(this.typesEntities, { 
        "Entities:jsonData.cod_ilike": `%${val}%`,
        'Entities:deleted_>=': this.inativos ? '1970-01-01' : undefined
      })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => this.isLoadingAutoComplete = false)
    },

    async getEntities (types, filter = {}) {
      this.isLoadingAutoComplete = true
      
      const filterParentId = {
        "Entities:parentId_=": this.parentId ? this.parentId : undefined,
      }
      return await this.getListEntitiesTreeLancamentoFinanceiro({
          _filter: { 'entityTypeId_In': types.toString(), ...filter, ...filterParentId },
          pageSize: this.$store.getters.ItensPaginacao,
          order: {'cod::int': 'ASC'}
      }).then(e => {
        this.listEntitiesSelect = e
      })
      .finally(() => this.isLoadingAutoComplete = false)
    }
  }
}
</script>